import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/conditions.md/" }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

const Conditions = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <div className="flex flex-col justify-center flex-grow items-center w-full h-full pt-20 pb-20">
        <div
          className="rounded bg-white m-6 p-6 max-w-7xl"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export default Conditions
